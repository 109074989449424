import { Empty, EmptyProps } from "antd";
import EmptyImage from "../../assets/images/empty.svg";

type Props = {
  imageStyle?: EmptyProps["imageStyle"];
  rootClassName?: string;
};
export default function EmptyComponent({
  imageStyle,
  rootClassName = "",
}: Props) {
  return (
    <Empty
      image={EmptyImage}
      imageStyle={{ marginBottom: 0, ...imageStyle }}
      description
      rootClassName={rootClassName}
    />
  );
}
