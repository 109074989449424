import { createSlice } from "@reduxjs/toolkit";
import { AUTH_SAVE } from "../../../helpers/config";
import { AuthUser, LoginInitialState } from "../../../types/redux";
import { loginUserAsync } from "./loginAPI";

const initialState: LoginInitialState<AuthUser> = {
  loading: false,
  token: "",
  login: null,
  siteRole: null,
  notificationToken: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.login = action.payload?.data;
      state.token = action.payload?.token;
      state.siteRole = action.payload?.siteRole;
    },
    userLogout: (state) => {
      localStorage.removeItem(`${AUTH_SAVE}`);
      state.login = null;
      state.token = "";
      state.siteRole = null;
    },
    setNotificationToken: (state, action) => {
      state.notificationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.login = action.payload.data;
        state.siteRole = action.payload.siteRole;
      })
      .addCase(loginUserAsync.rejected, (state) => {
        localStorage.removeItem(`${AUTH_SAVE}`);
        state.loading = false;
        state.siteRole = null;
      });
  },
});

export const { userLoggedIn, userLogout, setNotificationToken } =
  loginSlice.actions;
export default loginSlice;
