export default function DealersManageIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 13.7505H12M6 10.7505H12M13.5 1.00049V4.00049M9 1.00049V4.00049M4.5 1.00049V4.00049M2.25 2.50049H15.75C15.9489 2.50049 16.1397 2.57951 16.2803 2.72016C16.421 2.86081 16.5 3.05158 16.5 3.25049V17.5005C16.5 18.0972 16.2629 18.6695 15.841 19.0915C15.419 19.5134 14.8467 19.7505 14.25 19.7505H3.75C3.15326 19.7505 2.58097 19.5134 2.15901 19.0915C1.73705 18.6695 1.5 18.0972 1.5 17.5005V3.25049C1.5 3.05158 1.57902 2.86081 1.71967 2.72016C1.86032 2.57951 2.05109 2.50049 2.25 2.50049Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
