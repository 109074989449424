export default function CollateralIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 11.25H13.5M7.5 8.25H13.5M4.5 1.5V18M1.5 1.5H16.5C16.9142 1.5 17.25 1.83579 17.25 2.25V17.25C17.25 17.6642 16.9142 18 16.5 18H1.5C1.08579 18 0.75 17.6642 0.75 17.25V2.25C0.75 1.83579 1.08579 1.5 1.5 1.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
