import { App as AntApp, ConfigProvider, notification } from "antd";
import { onMessage } from "firebase/messaging";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import notificationSound from "./assets/audio/notification-sound.wav";
import NotificationIcon from "./assets/icons/NotificationIcon";
import EmptyComponent from "./components/common/EmptyComponent";
import Loader from "./components/common/Loader";
import { generateToken, messaging } from "./helpers/firebaseConfig";
import useApp from "./hooks/useApp";
import useAuthChecked from "./hooks/useAuthChecked";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { authRouters, publicRouters } from "./routers/routers";
import { setNotificationToken } from "./store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "./store/store";

type MSGType = {
  title: string;
  body: string;
};

const App: React.FC = () => {
  useApp();
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { authChecked } = useAuthChecked();
  const { token, login } = useAppSelector((state) => state.login);

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope: ",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed: ", error);
        });
    });
  }

  // Notification with firebase
  useEffect(() => {
    generateToken().then((el) => {
      dispatch(setNotificationToken(el));
    });

    const openNotification = (msg: MSGType) => {
      if (msg?.title !== "map") {
        api.open({
          message: msg?.title,
          description: msg?.body,
          icon: <NotificationIcon />,
        });
      }
    };

    // const playSound = () => {
    //   const audio = new Audio(notificationSound);
    //   audio.play();
    // };

    onMessage(messaging, (payload) => {
      const notification = payload?.notification as MSGType;
      openNotification(notification);
      // playSound();
    });
  }, [api, dispatch]);

  if (authChecked) {
    return <Loader />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#e82c4c",
          colorPrimaryText: "#3d4a5c",
          fontFamily: "Inter",
          colorBorder: "#D7DFE9",
        },
        components: {
          Button: {
            colorPrimary: "#0d0d0d",
            colorPrimaryHover: "#222",
          },
          Timeline: {
            tailColor: "#e82c4c",
            itemPaddingBottom: 18,
          },
          Checkbox: {
            colorBorder: "#afbaca",
            colorPrimary: "#e82c4c",
            colorPrimaryHover: "#e82c4c",
          },
        },
      }}
      renderEmpty={() => <EmptyComponent rootClassName="py-20" />}
    >
      <AntApp>
        {contextHolder}
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={token && login?.email ? "/admin/dashboard" : "/admin/login"}
              />
            }
          />

          {/* Public routers */}
          {publicRouters.map(({ path, element: Element }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <Suspense fallback={<Loader />}>
                  <Element />
                </Suspense>
              }
            />
          ))}

          {/* Auth sidebar routers */}
          {authRouters.map(({ path, element: Element, roles }, idx) => (
            <Route path={path} element={<SidebarLayout />} key={idx + path}>
              <Route
                path={path}
                element={
                  <AuthMiddleware roles={roles}>
                    <Suspense fallback={<Loader />}>
                      <Element />
                    </Suspense>
                  </AuthMiddleware>
                }
              />
            </Route>
          ))}
        </Routes>
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
