import { lazy } from "react";
import { RouteType } from "../types";

const NotFound = lazy(() => import("../pages/NotFound"));
const AdminLogin = lazy(() => import("../pages/admin/Login"));
const AdminDashboard = lazy(() => import("../pages/admin/Dashboard"));
const DealersManage = lazy(
  () => import("../pages/admin/Dealers/DealersManage")
);
const BankGuarantee = lazy(
  () => import("../pages/admin/Collateral/BankGuarantee")
);
const Cheque = lazy(() => import("../pages/admin/Collateral/Cheque"));
const Category = lazy(() => import("../pages/admin/ProductsManage/Category"));
const Products = lazy(() => import("../pages/admin/ProductsManage/Products"));
const Units = lazy(() => import("../pages/admin/ProductsManage/Units"));
const GiftItems = lazy(() => import("../pages/admin/GiftManage/GiftItems"));
const GiftAssign = lazy(() => import("../pages/admin/GiftManage/GiftAssign"));
const OrdersManage = lazy(
  () => import("../pages/admin/OrdersManage/OrdersManage")
);
const OrderDetails = lazy(
  () => import("../pages/admin/OrdersManage/OrderDetails")
);
const Notification = lazy(
  () => import("../pages/admin/Notification/Notification")
);
const Settings = lazy(() => import("../pages/admin/Settings/Settings"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/dealers-manage",
    element: DealersManage,
    roles: ["ADMIN"],
  },

  //Collateral
  {
    path: "/admin/collateral/bank-guarantee",
    element: BankGuarantee,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/collateral/cheque",
    element: Cheque,
    roles: ["ADMIN"],
  },

  // Products manage
  {
    path: "/admin/products-manage/category",
    element: Category,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/products-manage/products",
    element: Products,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/products-manage/units",
    element: Units,
    roles: ["ADMIN"],
  },
  // Gift management
  {
    path: "/admin/gift-manage/gift-items",
    element: GiftItems,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/gift-manage/gift-assign",
    element: GiftAssign,
    roles: ["ADMIN"],
  },
  // Order management
  {
    path: "/admin/orders-manage",
    element: OrdersManage,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/orders-manage/:id",
    element: OrderDetails,
    roles: ["ADMIN"],
  },
  // notification
  {
    path: "/admin/notification",
    element: Notification,
    roles: ["ADMIN"],
  },
  // settings
  {
    path: "/admin/settings",
    element: Settings,
    roles: ["ADMIN"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
