import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CollateralIcon from "../../../assets/icons/sidebar/CollateralIcon";
import DashboardIcon from "../../../assets/icons/sidebar/DashboardIcon";
import DealersManageIcon from "../../../assets/icons/sidebar/DealersManageIcon";
import GiftManageIcon from "../../../assets/icons/sidebar/GiftManageIcon";
import LedgerReportsIcon from "../../../assets/icons/sidebar/LedgerReportsIcon";
import NotificationIcon from "../../../assets/icons/sidebar/NotificationIcon";
import OrdersManageIcon from "../../../assets/icons/sidebar/OrdersManageIcon";
import ProductsManageIcon from "../../../assets/icons/sidebar/ProductsManageIcon";
import SettingsIcon from "../../../assets/icons/sidebar/SettingsIcon";
import SubmenuIcon from "../../../assets/icons/sidebar/SubmenuIcon";
import MainLogo from "../../../assets/images/main-logo.png";
import { APP_NAME } from "../../../helpers/config";
import {
  activePathString,
  pathString,
  stringToArray,
} from "../../../utils/array";
import Image from "../Image";

type MenuItem = Required<MenuProps>["items"][number];

type SidebarItemProps = {
  collapsed: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
};

export default function SidebarItems({
  collapsed,
  openMenuKeys,
  handleOpenMenu,
}: SidebarItemProps) {
  let dynamicParams = useParams();
  const location = useLocation();
  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  const AdminMenuItems: MenuItem[] = [
    getItem("Dashboard", "/admin/dashboard", <DashboardIcon />),
    getItem("Dealers Manage", "/admin/dealers-manage", <DealersManageIcon />),
    getItem("Collateral", "/admin/collateral", <CollateralIcon />, [
      getItem(
        "Bank Guarantee",
        "/admin/collateral/bank-guarantee",
        <SubmenuIcon />
      ),
      getItem("Cheque", "/admin/collateral/cheque", <SubmenuIcon />),
    ]),
    getItem(
      "Products Manage",
      "/admin/products-manage",
      <ProductsManageIcon />,
      [
        getItem("Category", "/admin/products-manage/category", <SubmenuIcon />),
        getItem("Products", "/admin/products-manage/products", <SubmenuIcon />),
        getItem("Create Unit", "/admin/products-manage/units", <SubmenuIcon />),
      ]
    ),
    getItem("Gift Manage", "/admin/gift-manage", <GiftManageIcon />, [
      getItem("Gift Items", "/admin/gift-manage/gift-items", <SubmenuIcon />),
      getItem("Gift Assign", "/admin/gift-manage/gift-assign", <SubmenuIcon />),
    ]),
    getItem("Orders Manage", "/admin/orders-manage", <OrdersManageIcon />),
    getItem("Notification", "/admin/notification", <NotificationIcon />),
    getItem("Ledger Reports", "/admin/ledger-reports", <LedgerReportsIcon />),
    getItem("Settings", "/admin/settings", <SettingsIcon />),
  ];

  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  return (
    <Sider
      trigger={null}
      theme="dark"
      collapsible
      collapsed={collapsed}
      className="!overflow-auto !h-screen !sticky top-0 custom-sidebar"
      width={312}
    >
      <div
        className={`h-20 flex items-center ${
          collapsed
            ? "pl-0 justify-center text-center"
            : "pl-5 !justify-start text-left"
        }`}
      >
        <Link to="/admin/dashboard">
          <Image
            height={collapsed ? 20 : 38}
            width="100%"
            src={MainLogo}
            alt={APP_NAME}
          />
        </Link>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[
          activePathString(
            location.pathname,
            "/",
            dynamicPath ? true : false,
            getActiveMenu(AdminMenuItems)
          ),
        ]}
        openKeys={openMenuKeys}
        onOpenChange={handleOpenMenu}
        items={AdminMenuItems}
        className={`sidebar-menu-item ${collapsed ? "sidebar_collapsed" : ""}`}
      />
    </Sider>
  );
}
