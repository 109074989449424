export default function GiftManageIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 10.7499V17.4999C17.5 17.6988 17.421 17.8895 17.2803 18.0302C17.1397 18.1708 16.9489 18.2499 16.75 18.2499H3.25C3.05109 18.2499 2.86032 18.1708 2.71967 18.0302C2.57902 17.8895 2.5 17.6988 2.5 17.4999V10.7499M10 6.24987V18.2499M10 6.24987C10 6.24987 13.182 6.24987 14.2426 5.18921C14.6642 4.76717 14.9009 4.195 14.9007 3.59848C14.9006 3.00197 14.6635 2.42993 14.2417 2.00813C13.8199 1.58633 13.2479 1.34929 12.6514 1.34912C12.0549 1.34895 11.4827 1.58567 11.0607 2.00723C10 3.06789 10 6.24987 10 6.24987ZM10 6.24987C10 6.24987 6.81802 6.24987 5.75736 5.18921C5.3358 4.76717 5.09909 4.195 5.09926 3.59848C5.09942 3.00197 5.33647 2.42993 5.75827 2.00813C6.18007 1.58633 6.7521 1.34929 7.34862 1.34912C7.94513 1.34895 8.5173 1.58567 8.93934 2.00723C10 3.06789 10 6.24987 10 6.24987ZM1.75 6.24987H18.25C18.6642 6.24987 19 6.58565 19 6.99987V9.99987C19 10.4141 18.6642 10.7499 18.25 10.7499H1.75C1.33579 10.7499 1 10.4141 1 9.99987V6.99987C1 6.58565 1.33579 6.24987 1.75 6.24987Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
