export default function SubmenuIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00058 12.75H17.25M0.750576 12.75H2.25M6.00058 6.75H17.25M0.750576 6.75H2.25M6 0.75H17.25M0.75 0.75H2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
