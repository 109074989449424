import { Drawer, Layout } from "antd";
import withSidebar from "../HOC/withSidebar";
import SidebarContent from "../components/common/sidebar/SidebarContent";
import SidebarItems from "../components/common/sidebar/SidebarItems";

export type SidebarProps = {
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  return (
    <Layout className="min-h-screen">
      <div className="md:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <SidebarItems
            collapsed={false}
            openMenuKeys={openMenuKeys}
            handleOpenMenu={handleOpenMenu}
          />
        </Drawer>
      </div>
      <div className="hidden md:block">
        <SidebarItems
          collapsed={collapsed}
          openMenuKeys={openMenuKeys}
          handleOpenMenu={handleOpenMenu}
        />
      </div>

      {/* Sidebar content is main children component, where show children data. This component contains Header items like: Profile and Notification  */}
      <SidebarContent
        collapsed={collapsed}
        handleCollapsed={handleCollapsed}
        handleShowSidebar={handleShowSidebar}
      />
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
