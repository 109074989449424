import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB5q95gw7nSmSN3Xedugd6wIZjox4XfEXQ",
  authDomain: "edotco-1fd4c.firebaseapp.com",
  projectId: "edotco-1fd4c",
  storageBucket: "edotco-1fd4c.appspot.com",
  messagingSenderId: "117506187339",
  appId: "1:117506187339:web:be6b434ffaaf8f8fd9adce",
  measurementId: "G-4SMG4P6EN4",
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BDdk7K6lcWoWdoiwpk8_oVn7s9SDjeo0qrkBuuy_-DdKp48kg5geKS4e-7pAqmocDqsvFLocVb0LjqS8tIPDEnk",
    });

    return token;
  }
};
