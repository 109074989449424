export default function OrdersManageIcon({ color = "currentColor" }) {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.25 16C16.2145 16 15.375 16.8395 15.375 17.875C15.375 18.9105 16.2145 19.75 17.25 19.75C18.2855 19.75 19.125 18.9105 19.125 17.875C19.125 16.8395 18.2855 16 17.25 16ZM17.25 16H6.54545L3.93015 1.61584C3.89873 1.44303 3.80766 1.28673 3.67281 1.17419C3.53796 1.06164 3.36789 1 3.19225 1H1.5M5.86363 12.25H17.6345C17.9858 12.25 18.3259 12.1267 18.5956 11.9016C18.8653 11.6765 19.0475 11.3639 19.1103 11.0183L20.25 4.75H4.5M9.375 17.875C9.375 18.9105 8.53553 19.75 7.5 19.75C6.46447 19.75 5.625 18.9105 5.625 17.875C5.625 16.8395 6.46447 16 7.5 16C8.53553 16 9.375 16.8395 9.375 17.875Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
