export default function LedgerReportsIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2502 0.75H2.24951C2.0506 0.75 1.85983 0.829018 1.71918 0.96967C1.57853 1.11032 1.49951 1.30109 1.49951 1.5V18C1.49951 18.1989 1.57853 18.3897 1.71918 18.5303C1.85983 18.671 2.0506 18.75 2.24951 18.75H15.7502C15.8487 18.75 15.9463 18.7306 16.0373 18.6929C16.1283 18.6552 16.2109 18.6 16.2806 18.5303C16.3502 18.4607 16.4055 18.378 16.4432 18.287C16.4808 18.196 16.5002 18.0985 16.5002 18V6M11.2502 0.75L16.5002 6M11.2502 0.75V6H16.5002M6.00024 13.5H12.0002M6.00024 10.5H12.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
