export default function NotificationIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.00001 15.7499V16.4999C6.00001 17.2956 6.31608 18.0587 6.87868 18.6213C7.44129 19.1839 8.20436 19.4999 9.00001 19.4999C9.79566 19.4999 10.5587 19.1839 11.1213 18.6213C11.6839 18.0587 12 17.2956 12 16.4999V15.7499M2.26904 7.49994C2.2678 6.61045 2.44262 5.72951 2.78343 4.9079C3.12424 4.08628 3.6243 3.34025 4.25477 2.7128C4.88525 2.08535 5.63368 1.58889 6.45693 1.25204C7.28017 0.915187 8.16194 0.744607 9.05142 0.75013C12.7629 0.777722 15.7317 3.86275 15.7317 7.58468V8.24994C15.7317 11.6077 16.4342 13.5561 17.0529 14.621C17.1196 14.7348 17.1551 14.8642 17.1558 14.996C17.1565 15.1279 17.1224 15.2576 17.0569 15.3721C16.9915 15.4866 16.8971 15.5818 16.7831 15.6482C16.6691 15.7145 16.5397 15.7496 16.4078 15.7499H1.59222C1.46034 15.7496 1.33087 15.7145 1.21689 15.6481C1.1029 15.5818 1.00844 15.4866 0.943012 15.372C0.877587 15.2575 0.843521 15.1278 0.84425 14.9959C0.844978 14.864 0.880476 14.7346 0.947161 14.6209C1.56622 13.5559 2.26904 11.6075 2.26904 8.24994L2.26904 7.49994Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
