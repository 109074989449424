export default function ProductsManageIcon() {
  return (
    <span className="w-6 flex justify-center">
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 7.5L17.25 1.5H6.75L1.5 7.5M22.5 7.5L12 18.75M22.5 7.5H1.5M12 18.75L1.5 7.5M12 18.75L16.6023 7.5L12 1.5L7.39772 7.5L12 18.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
